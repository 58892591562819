import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, CustomIcons } from "components";
import { ThemeProvider, useTheme } from '../context/ThemeContext';

function LayoutPage(body = '', color = 'bg-amber-a700') {
  const [isLoading, setIsLoading] = useState(true);
  const { setTheme } = useTheme();

  useEffect(() => {  
    if (isLoading === true) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>MoonAI</title>
        <meta name="description" content="IA" />
      </Helmet>
      <div className={`w-full h-screen min-h-[300px] scrollbar-thumb-gray-400 scrollbar-track-amber-a700 ${color}`}>
        <header className="bg-white-a700">
        </header>
        <div className="flex sm:flex-col h-full scrollbar-thin overflow-y-auto">
          <ThemeProvider>
          {(isLoading === false) ? (body) : (<div className="flex m-auto items-center justify-center h-screen"><CustomIcons iconName="wired-outline-74-navigation" backgroundColor="bg-white-a700" /></div>)}
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default LayoutPage;
