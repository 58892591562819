import React from 'react';
import './Modal.css';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay scrollbar-thumb-gray-400 scrollbar-track-amber-a700 px-2" onClick={onClose}>
      <div className="modal-content scrollbar-thin overflow-y-auto" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export { Modal };