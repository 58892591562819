import React, { useState, useEffect, useRef } from "react";
import {
  Img,
  Heading,
  Input,
  Text,
  Button,
  ChatMessage,
  MessageInput,
  VideoCard,
  ProductCard,
  ProductComparison,
  TextMessage,
  MyImageGallery,
  LoadingIndicator,
  CustomIcons,
  ImageGallery,
  Modal,
  ModalRotate
} from "components";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";
import LayoutView from "pages/layout";
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../../context/ThemeContext';
import useChat from '../../hooks/useChat';

export default function PageConversation() {
  const [randomNumber] = useState(Math.floor(Math.random() * 3) + 1);
  const { state } = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isGreeted, setIsGreeted] = useState(false);
  const [errors, setErrors] = useState([{}]);
  const [isVisibleAlert, setIsVisibleAlert] = useState(true);
  const navigate = useNavigate();

  const [isMediaContent, setIsMediaContent] = useState(false);
  const question = state?.question || null;
  if (question === null) {
    navigate(`/`, { state: {} });
  }
  const { setTheme } = useTheme();
  const {
    messages,
    setMessages,
    handleSendMessage,
    isTyping,
    currentMessage,
    isCreatedWS,
    images,
    countMessagesIterated,
    mediaCalled
  } = useChat();
  useEffect(() => {
    if (isCreatedWS && isPageLoading === true && question !== null && sessionStorage.getItem('sessionId') !== null && isGreeted === false) {
      handleSend(question);
      setIsPageLoading(false);
      setIsGreeted(true);
    }
  }, [sessionStorage.getItem('sessionId'), isCreatedWS]);

  const isMobileAndPortrait = () => {
    return window.matchMedia("(max-width: 550px) and (orientation: portrait)").matches;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisibleAlert(false);
    }, 5000); // 5 segundos

    return () => clearTimeout(timer); 
  }, []);


  useEffect(() => {
    if (isCreatedWS === true && isTyping === false) {
      setIsLoading(false);
    }else if (isCreatedWS === true && isTyping === true) {
      setIsLoading(true);
    }
  }, [isTyping]);

  const [newContent, setNewContent] = useState(false);

  const messagesEndRef = useRef(null);
  const mediaEndRef = useRef(null);
  const handleSend = (message) => {
    setIsLoading(true);
    const newMessage = {
      sender: 'You',
      type: 'text',
      text: message,
      avatar: <CustomIcons iconName="wired-flat-44-avatar-user-in-circle" size={50} loop={false} />,
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    };
    setMessages(prevMessages => [...prevMessages, newMessage]);
    handleSendMessage(newMessage);
  };

  useEffect(() => {
    setNewContent(true);
    if (mediaEndRef.current) {
      console.info('enter on if messagesendred scroll')
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      mediaEndRef.current.scrollIntoView({ behavior: 'smooth'});
      setNewContent(false);
    } else if(messagesEndRef.current){
      console.info('enter on else if messageendref ');
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      //mediaEndRef.current.scrollIntoView({ behavior: 'smooth'});
    } else {
      console.info('enter on else useeffect');
    }
  }, [messages, isTyping, countMessagesIterated, images, newContent, mediaCalled]);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const renderImages = (images) => {
    return (
      <div className="w-full h-full">
        {images.map((msg, index) => (
          <div key={index} className="mb-4">
            {msg?.images?.length > 0 && (
              <MyImageGallery 
                images={msg.images} 
                title="Terra Images" 
                onImageClick={handleImageClick}
              />
            )}
            
            {msg?.videos?.length > 0 && (
              <VideoCard videos={msg.videos} />
            )}
        
          </div>
        ))}
        <div ref={mediaEndRef} />
      </div>
    );
  };

  const renderMessage = (msg, index) => {    
    if(msg && msg.text && !msg.text.includes('media-content')){
      return (
        <div key={index} className="py-1">
          <TextMessage
            sender={msg.sender}
            message={msg.text || (typeof msg.content === 'string' ? msg.content : JSON.stringify(msg.content))}
            avatar={msg.avatar}
            time={msg.time}
          />
        </div>
      );
    }
  };

  return LayoutView(
    <>
    <div className="w-full h-full flex flex-row gap-1 sm:flex-col">
      <div className="flex w-1/2 sm:w-full h-full flex-col items-start sm:items-end justify-end gap-3.5 sm:gap-0 md:gap-1 px-8 sm:px-2 md:px-4 pt-2 pb-6 sm:pb-4 md:pb-4">
        <div className="flex flex-col justify-end w-full h-fit">
          <div className="flex flex-1 w-full h-full p-2 rounded-3xl scrollbar-thumb-gray-400 scrollbar-track-amber-a700">
            <div className="scrollbar-thin w-full max-h-[calc(100vh-150px)] sm:max-h-[calc(100vh-158px)] md:max-h-[calc(100vh-142px)] h-full overflow-y-auto">
              {messages.map((msg, index) => renderMessage(msg, index))}
              <div ref={messagesEndRef} />
              {isTyping && (
                <div className="py-1">
                  <TextMessage
                    sender={`${process.env.REACT_APP_AGENT_NAME}`}
                    message={currentMessage}
                    avatar={<Img src="images/servicio-al-cliente-mujer.svg" alt="Executive" width="50" height="60" className={"min-w-[50px]"} />}
                    time={new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  />
                </div>
              )}
              {isTyping && <LoadingIndicator />}
              <div ref={messagesEndRef} />
            </div>
          </div>
          <div className="w-full">
            <MessageInput onSend={handleSend} isDisabled={isLoading} />
          </div>
        </div>
      </div>
      <div className="flex w-1/2 h-full sm:h-fit bg-gray-100 px-2.5 sm:w-full sm:px-0 pb-4 sm:pb-0 md:pb-2">
        <a href="/"><Img
          src={`images/img_settings_essen.png`}
          alt="Settings"
          className="h-[52px] w-auto fixed top-0 right-0 z-10 p-2"
        /></a>
        <Tabs
          className="w-full mt-2 flex flex-col gap-4 py-2 scrollbar-thumb-amber-a700 scrollbar-track-white-a700 sm:hidden"
          selectedTabClassName="!text-white-a700 bg-black-900 rounded"
          selectedTabPanelClassName="px-1 py-1 md:px-2 sm:px-4 relative tab-panel--selected"
        >
          <TabList className="mx-4 flex flex-row justify-start items-center sm:mx-0 mt-5 sm:mt-1">
            <Tab className="px-4 py-2 text-sm font-medium uppercase tracking-[0.40px] text-black-900_dd items-center hidden">
              Info
            </Tab>
          </TabList>
          <TabPanel className={`flex flex-col ${(images && images.length > 0) ? "gap-2" : "gap-0"} p-0 bg-white-a700 border border-solid border-black-900_3a rounded-[16px] h-[95vh] sm:h-[80vh] scrollbar-thumb-gray-400 scrollbar-track-amber-a700`}>
            {(images && images.length > 0) ? (
              <div className="w-full h-full scrollbar-thin overflow-y-auto">
                {renderImages(images)}
              </div>
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <Img
                  src={`images/essen${randomNumber}.jpg`}
                  alt="Container"
                  className="w-full h-full object-cover rounded-[16px] py-2 px-1 self-center"
                />
              </div>
            )}
            <div ref={mediaEndRef} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
    <Modal isOpen={!!selectedImage} onClose={closeModal}>
      <img src={selectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </Modal>
    { (isMobileAndPortrait()) && <ModalRotate isOpen={!!isVisibleAlert}>
      Para una mejor experiencia multimedia, usar su dispositivo en modo horizontal.
    </ModalRotate> }
  </>
  );
}